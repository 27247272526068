import React from "react"
import { Link } from "gatsby"
import clsx from "clsx"

const renderPreviousLink = (previousPagePath) => {
  if (previousPagePath) {
    return (
      <Link
        to={previousPagePath}
        className="flex items-center group btn btn-ghost"
      >
        <span>Previous</span>
      </Link>
    )
  } else {
    return (
      <div className="text-gray-300 dark:text-gray-500 prev-next-text">
        Previous
      </div>
    )
  }
}

const renderNextLink = (nextPagePath) => {
  if (nextPagePath) {
    return (
      <Link to={nextPagePath} className="flex items-center group btn btn-ghost">
        <span>Next</span>
      </Link>
    )
  } else {
    return (
      <div className="text-gray-300 dark:text-gray-500 prev-next-text w-[70px] text-right">
        Next
      </div>
    )
  }
}

export const Pagination = ({ ctx }) => {
  const { humanPageNumber, nextPagePath, previousPagePath } = ctx
  // return empty string if there is only one page
  if (humanPageNumber === 1 && !nextPagePath) {
    return ""
  }
  return (
    <nav className="flex justify-between mt-16">
      {renderPreviousLink(previousPagePath)}
      <span
        aria-current="page"
        className={clsx(
          "page-numbers current",
          "font-bold font-info text-center"
        )}
      >
        {humanPageNumber}
      </span>
      {renderNextLink(nextPagePath)}
    </nav>
  )
}
