import React from "react"
import { Archivepost } from "./ArchivePost"

export const PostsList = ({ posts, ...props }) => {
  return (
    <div {...props} className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full p-4 bg-gray-100 dark:bg-gray-800 rounded-lg shadow-md">
      {posts?.map((post, index) => {
        return <Archivepost key={post.id} post={post} isFirst={index === 0}
        />
      })}
    </div>
  )
}
