import { Link } from "gatsby"
import React from "react"

const CategoryCTA = ({
  title,
  description,
  buttonTitle,
  uri,
  className,
  textClasses,
  buttonClasses,
  bgClass,
  ...props
}) => {
  return (
    <div
      className={`rounded-xl shadow-lg p-6 flex flex-col justify-between ${bgClass} ${className}`}
      {...props}
    >
      <div>
      <Link to={uri}>
  <h3
    className={`mb-5  underline-offset-4 text-3xl ${textClasses} dark:text-white`}
  >
    {title}
  </h3>
</Link>

        <p
          dangerouslySetInnerHTML={{ __html: description }}
          className="mb-7 text-base leading-7"
        />
      </div>
      <Link
        to={uri}
        className={`flex py-2 justify-center text-white rounded-md hover:underline dark:bg-[#ffffff50] ${buttonClasses}`}
      >
        {buttonTitle}
      </Link>
    </div>
  )
}

export default CategoryCTA
