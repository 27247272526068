import React from "react"
import { Archivetitle } from "./ArchiveTitle"
import { Description } from "./Description"
import { Pagination } from "./Pagination"
import { PostsList } from "./PostsList"
//import AdContainer from "../AdContainer"
import { useThemeOptions } from "@gatsbywpthemes/gatsby-theme-blog-data/src/hooks"
import { Archivepost } from "./ArchivePost"
import CategoryCTA from "./CategoryCTA"
import clsx from "clsx"

export const landingPageCTAs = [
  {
    title: "Video Tutorials",
    description:
      "We offer high-quality video that provide comprehensive guidance and support. Our tutorials are designed to help you master the skills and knowledge you need to succeed, with clear and engaging content that is easy to follow and understand",
    textClasses: "text-[#18a46f] ",
    buttonClasses: "bg-[#18a46f]",
    bgClass: "bg-[#f1fdf8] dark:bg-gradient-to-r from-green-800 to-green-700",
    buttonTitle: "Explore",
    uri: "/topic/videos/",
  },

  {
    title: "Developer Tools",
    description:
      "Access over 50+ developer tools that can help simplify your workflow and boost your productivity. Our toolkit includes a wide range of powerful tools and resources designed to streamline your development process and enhance the quality of your work",
    buttonTitle: "Explore",
    textClasses: "text-[#AA1994]",
    buttonClasses: "bg-[#AA1994]",
    bgClass: "bg-[#fbecf9] dark:bg-gradient-to-r from-purple-800 to-violet-900",
    uri: "/tools/",
  },
  {
    title: "Tutorials & guides",
    description:
      "We provide high-quality tutorials and guides on web development and programming, catering to both beginners and advanced users alike. Our comprehensive resources aim to help you learn, develop, and master the skills necessary to excel in your field, from the basics to the more advanced topics.",
    buttonTitle: "Explore",
    textClasses: "text-[#F55700]",
    buttonClasses: "bg-[#F55700]",
    bgClass: "bg-[#fef1f0] dark:bg-gradient-to-r from-orange-600 to-rose-600",
    uri: "/topic/tutorials/",
  },
  
]

export const ArchiveContent = ({
  posts,
  ctx,
  name,
  description,
  isLandingPage,
}) => {
  const {
    archiveSidebarPosition: archiveSidebar,
    layoutWidth,
    widgetAreas: { sidebarWidgets },
  } = useThemeOptions()

  const hasSidebar = sidebarWidgets && archiveSidebar
  const archiveWidth = layoutWidth.archive

  const isFirstLandingPage = isLandingPage && ctx.humanPageNumber === 1
  const [firstPost, ...rest] = posts

  return (
    <div
      className={`mainContainer mx-auto ${hasSidebar
          ? `max-w-xl`
          : `${archiveWidth === "md"
            ? "max-w-md"
            : archiveWidth === "lg"
              ? "max-w-lg"
              : "max-w-xl"
          }`
        }`}
    >
      {name && <Archivetitle name={name} text="Posts for " className="mb-10" />}
      {description && (
        <Description description={description} className="mb-10" />
      )}

      {isFirstLandingPage && <Archivepost post={firstPost} isSeparated />}

      {isFirstLandingPage && (
        <div className="mt-6">

        </div>
      )}

      {isFirstLandingPage && (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 mt-16 mb-16 gap-8">
          {landingPageCTAs.map((values, index) => (
            <CategoryCTA key={values.title + index} {...values} />
          ))}
        </div>
      )}

      {isFirstLandingPage && (
   <p class="inline-block text-white font-bold py-2 px-4 rounded-md border-2 border-purple-600 transition duration-300 transform hover:-translate-y-1 hover:shadow-lg bg-gradient-to-br from-purple-600 to-pink-600 hover:from-pink-600 hover:to-purple-600 hover:-rotate-3">
   Latest Articles
 </p>
 
  
   
    
     
      )}
      <div className={clsx(!isLandingPage && "card p-2")}>
        <PostsList posts={isLandingPage ? rest : posts} />
      </div>

      <Pagination ctx={ctx} />

    
    </div>
  )
}
