import React from "react";
import { PostEntryContent } from "../post/PostEntryContent";
import { PostEntryTitle } from "../post/PostEntryTitle";
import clsx from "clsx";
import { Author } from "../post/Author";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Newsletter } from "../widgets/Newsletter";

export const Archivepost = ({
  isFirst = false,
  isSeparated = false,
  post,
  className = null,
  ctx,
  ...props
}) => {
  const author = post.author.node;

  const imageSize = isSeparated ? 100 : 60;

  return (
<article className={clsx("rounded-lg overflow-hidden shadow-2xl", className)} {...props}>
      <div
        className={clsx(
          "flex py-5 px-1 flex-col",
          isSeparated ? "" : "lg:px-5",
          isSeparated ? "lg:flex-row" : "md:flex-row",
          isSeparated && "space-y-12 lg:space-y-0"
        )}
      >
        {author && (
          <div
            className={clsx(
              "mb-4 md:mb-0 shrink-0 flex flex-row items:center md:items-start",
              isSeparated ? "md:mr-6" : "md:mr-4"
            )}
          >
            <Link
              to={author.uri}
              className="ease-in-out hover:-translate-y-0.5 shadow-md transform transition duration-300 hover:scale-105"
            >
              {author?.avatar.url ? (
                <img
                  src={author.avatar.url.replace("s=96", "s=200")}
                  width={imageSize}
                  height={imageSize}
                  alt={author.name}
                  className="rounded-md border-2 border-blue-500 hover:border-blue-700 transition duration-300 shadow-2xl"
                />
              ) : (
                <StaticImage
                  src="../../../static/assets/default-avatar.png"
                  alt="Default avatar"
                  width={70}
                  height={70}
                  className="rounded-md border-2 border-blue-500 hover:border-blue-700 transition duration-300 shadow-2xl"
                />
              )}
            </Link>

            <WrittenBy post={post} className="ml-4 md:hidden flex items-end" />
          </div>
        )}
        <div>
          <WrittenBy post={post} className="mb-2 hidden md:block" />

          <PostEntryTitle
            post={post}
            location="archive"
            className={clsx("mb-4  text-2xl", isSeparated && "text-4xl")}
          />

          {isSeparated && (
            <div className="mb-4">
              <PostTags post={post} />
            </div>
          )}

          <div className="excerpt-container">
            <PostEntryContent post={post} location="archive" className="excerpt text-lg leading-7" />
          </div>

          {!isSeparated && <PostTags post={post} />}
          {isSeparated && (
            <Link
              to={post.uri}
              className="inline-block text-base bg-gradient-to-r from-blue-500 to-purple-600 text-white border-b-4 border-blue-700 hover:bg-gradient-to-r hover:from-blue-600 hover:to-purple-700 hover:border-transparent pb-2 px-4 rounded-lg transition duration-300 ease-in-out transform hover:-translate-y-
              0.5 mt-4 ml-2"
              >
              Continue Reading
              </Link>
              )}
              </div>
              {isSeparated && <Newsletter className="lg:ml-6 pl-6 pr-9" />}
              </div>
              </article>
              );
              };
              
              const WrittenBy = ({ post, className, ...props }) => {
              if (!post.author.node) return null;
              
              return (
              <div className={`${className}`} {...props}>
              <span>
              <Author
                     author={post.author.node}
                     className="font-extrabold dark:!text-transparent text-base bg-clip-text bg-gradient-to-r from-yellow-200 via-green-200 to-green-300"
                   />
              <span className="text-sm text-gray-400"> wrote </span>
              </span>
              </div>
              );
              };
              
              const PostTags = ({ post, className, ...props }) => {
              const date = new Date(post.date).toLocaleDateString("en-Us", {
              year: "numeric",
              month: "long",
              day: "numeric",
              });
              
              return (
              <div>
              <span className="text-sm">
              <BrowserSVG /> {date} in{" "}
              {post.categories.nodes.map(({ name, id, uri }, index, arr) => (
              <span key={id}>
              <Link to={uri} className="hover:underline">
              {name}
              </Link>
              {index + 1 < arr.length && " & "}
              </span>
              ))}
              </span>
              </div>
              );
              };





const BrowserSVG = () => {

  
  return (
  <svg
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  x="0px"
  y="0px"
  width="20px"
  height="20px"
  className={clsx("inline-block pb-1 mr-1", "fill-white")}
  viewBox="0 0 1000 1000"
  enableBackground="new 0 0 1000 1000"
  >
  <g>
  <g transform="translate(0.000000,448.000000) scale(0.100000,-0.100000)">
  <path d="M877.2,3737.4c-256.5-61.3-534-279.5-658.4-516.8c-126.3-241.2-118.7,23-118.7-4128.6v-3768.8l65.1-65.1l65.1-65.1H5000h4769.8l65.1,65.1l65.1,65.1v2932.3v2932.3l-65.1,65.1c-45.9,45.9-82.3,65.1-124.4,65.1c-78.5,0-158.9-42.1-189.5-99.5c-19.1-34.4-24.9-671.8-28.7-2829l-3.8-2784.9H5000H511.6v3636.7V2878l49.8,105.3c70.8,153.1,155,239.3,300.5,310.1l128.2,63.2l3960.2,5.7c3452.9,3.8,3975.5,0,4073.1-24.9c199.1-49.8,321.6-155,415.4-354.1c47.9-99.5,49.8-124.4,55.5-472.8l7.7-369.4l-4233.9-3.8l-4233.9-5.7l-55.5-63.2c-99.5-111-65.1-264.1,70.8-321.6c97.6-40.2,8628.5-40.2,8726.1,0c116.7,49.8,124.4,86.1,124.4,627.8s-15.3,648.9-118.7,846c-78.5,149.3-279.5,350.3-428.7,428.7c-241.2,126.3,47.9,118.7-4362.1,116.8C1604.5,3764.2,972.9,3760.4,877.2,3737.4z" />
  <path d="M1409.3,2910.5c-105.3-84.2-112.9-208.6-19.1-302.4c74.6-74.7,153.1-86.1,241.2-32.5c174.2,101.4,101.4,369.4-99.5,369.4C1482,2945,1432.3,2931.6,1409.3,2910.5z" />
  <path d="M2203.6,2889.5c-130.2-130.2-32.5-346.5,157-346.5c82.3,0,189.5,107.2,189.5,189.5C2550.1,2922,2333.8,3019.6,2203.6,2889.5z" />
  <path d="M3032.4,2897.1c-72.7-61.3-91.9-137.8-53.6-225.8c28.7-70.8,111-128.3,183.8-128.3c118.7,0,225.9,134,201,250.8c-17.2,80.4-111,151.2-201,151.2C3110.9,2945,3070.7,2929.7,3032.4,2897.1z" />
  <path d="M3264-142.4c-59.3-34.4-897.7-1089.1-913-1150.3c-7.7-28.7-7.7-70.8,0-93.8c7.7-23,210.5-289,451.7-591.5c480.4-601,499.6-620.1,633.5-564.6c84.2,36.4,128.2,99.5,128.2,185.7c0,84.2,11.5,67-405.8,589.5c-214.4,266.1-336.9,434.5-327.3,449.8c7.7,11.5,164.6,212.5,350.3,444.1c187.6,233.5,348.4,440.2,359.8,463.2c70.8,130.1-21,292.8-162.7,292.8C3338.7-119.4,3287-129,3264-142.4z" />
  <path d="M5480.5-169.2c-63.2-61.2-1274.8-2076.7-1293.9-2153.3c-19.1-74.6,38.3-178,116.8-216.3c76.6-36.4,155-24.9,214.4,28.7c44,40.2,1253.7,2048,1286.2,2134.2c15.3,38.3,13.4,72.7,0,116.8C5756.1-115.6,5587.7-69.6,5480.5-169.2z" />
  <path d="M6491.1-173c-61.3-61.3-74.7-160.8-32.5-237.3c11.5-23,172.3-229.7,359.8-463.2c185.7-231.6,342.6-432.6,350.3-444.1c9.6-15.3-112.9-183.7-327.3-449.8c-417.3-522.5-405.8-505.3-405.8-589.5c0-86.1,44-149.3,128.3-185.7c132.1-53.6,155-34.5,612.5,535.9c229.7,287.1,432.6,551.3,451.7,589.5c23,47.9,30.6,88,21.1,122.5c-15.3,63.2-853.7,1117.8-913,1152.3C6657.6-98.4,6552.3-111.8,6491.1-173z" />
  </g>
  </g>
  </svg>
  )
  }